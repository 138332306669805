import { graphql } from "gatsby";

import {
  SectionContainer,
  SectionWrapper
} from "../common/Section";

import { Layout } from "../components/Layout";
import { Head } from "../components/Head";

import { CallToAction, LargeCallToAction } from "../components/CallToAction";
import { TestimonialsCarousel } from "../components/Carousel";
import { PageHero } from "../components/Hero";
import { PromotionsItem, Stack } from "../components/Stack";


interface Props {
  data: any
};


const PromotionsPage = ({
  data
}: Props ) => {
  const promotions = data?.allPrismicPromotion?.edges || [];
  const socials = data?.allPrismicSocial?.edges || [];
  const testimonials = data?.allPrismicTestimonial?.edges || [];

  const calltoaction = data?.prismicCallToAction?.data || {};
  const global = data?.prismicSiteInfo?.data || {};

  const promotionspage = data?.prismicPromotionsPage?.data || {};

  return (
    <Layout
      global={ global }
      socials={ socials }
    >
      <Head
        title={ promotionspage.title.text }
        description={ promotionspage.description.text }
      />

      <SectionWrapper
        background="background.linen"
        padding="0 0 45px"
      >
        <SectionContainer>
          <PageHero
            heading={ promotionspage.hero_heading.text }
            description={ promotionspage.hero_description.html }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="78px 0 65px"
      >
        <SectionContainer>
          <Stack
            align="center"
            dividerVisible
          >
            { promotions.map(( node: any, _index: number ) => (
              <PromotionsItem
                key={ node.node.uid }
                data={ node.node.data }
              />
            ))}
          </Stack>
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        background="background.linkwater"
      >
        <SectionContainer>
          <LargeCallToAction
            heading={ promotionspage.large_cta_heading.text }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="80px 0 46px"
      >
        <TestimonialsCarousel
          heading={ promotionspage.testimonials_heading.text }
          description={ promotionspage.testimonials_description.html }
          nodes={ testimonials }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="60px 0"
      >
        <SectionContainer>
          <CallToAction
            heading={ calltoaction.heading.text }
            description={ calltoaction.description.html }
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export const query = graphql`
  query PromotionsPageQuery {
    allPrismicPromotion {
      edges {
        node {
          _previewable
          data {
            service {
              text
            }
            discount {
              text
            }
            description {
              html
            }
          }
          uid
        }
      }
    }

    allPrismicSocial {
      edges {
        node {
          _previewable
          data {
            platform
            link {
              url
            }
          }
          uid
        }
      }
    }

    allPrismicTestimonial {
      edges {
        node {
          _previewable
          data {
            author {
              text
            }
            review {
              text
            }
            source
          }
        }
      }
    }

    prismicCallToAction {
      _previewable
      data {
        heading {
          text
        }
        description {
          html
        }
      }
      uid
    }

    prismicSiteInfo {
      _previewable
      data {
        phone_number {
          text
        }
        phone_number_link {
          url
        }

        email {
          text
        }
        email_link {
          url
        }

        address {
          text
        }

        accreditations {
          image {
            alt
            gatsbyImageData(
              height: 40
              placeholder: BLURRED
            )
          }
        }

        copyright {
          text
        }
      }
      uid
    }

    prismicPromotionsPage {
      _previewable
      data {
        title {
          text
        }
        description {
          text
        }

        hero_heading {
          text
        }
        hero_description {
          html
        }

        large_cta_heading {
          text
        }

        testimonials_heading {
          text
        }
        testimonials_description {
          html
        }
      }
    }
  }
`;


export default PromotionsPage;
